/* .App {
    text-align: center;
} */

/* .chat-container {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
} */

/* .chat-content {
    max-height: 90%;
    overflow-y: auto;
} */

.chat-input {
    padding: 20px 10px 10px 10px;
    border-top: 1px solid #ddd;
}

/* .message {
    max-width: 70%;
    padding: 8px;
    margin: 5px;
    border-radius: 10px;
} */

/* .user-message {
    align-self: flex-end;
    background-color: green;
    color: white;
    text-align: left;

} */

/* .ai-message {
    align-self: flex-start;
    background-color: #FFFFFF;
    color: black;
    border: 1px solid gray;
    text-align: left;
} */

input[type='text'] {
    width: 80%;
    padding: 5px;
    border: 1px solid green;
    border-radius: 5px;
    background-color: #f4f4f4;
    height: 40px;
}

button {
    padding: 5px;
    background-color: #2185d0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Improved AI Styles */

.chat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    /* background-color: #f9f9f9;
    border: 1px solid #e0e0e0; */
    /* border-radius: 10px; */
  }


/* Chat container styles */
.chat-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;
    /* padding: 1rem; */
    /* background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 10px; */
  }
  
  /* Message styles */
  .message {
    display: flex;
    align-items: flex-start;
  }
  
  /* User and AI alignment */
  .user-message {
    justify-content: flex-end;
  }

  .ai-message {
    justify-content: flex-start;
  }
    
  /* Message bubbles */
  .message-bubble {
    max-width: 90%;
    padding: 0.75rem 1rem;
    border-radius: 15px;
    border: 1px solid #e0e0e0;
    font-size: 0.9rem;
    line-height: 1.5;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .user-bubble {
    background-color: #3B82F6;
    color: #ffffff;
    border-top-right-radius: 0;
  }
  
  .ai-bubble {
    /* background-color: #e8e8e8; */
    color: #000000;
    border-top-left-radius: 0;
  }
  