/* Style the left navigation */
.leftNav {
  position: fixed;
  height: auto;
  background-color: #f4f4f4;
  max-width: 235px;
  overflow-y: scroll;
  top: 51px;
  left: 0;
  bottom: 0;

  max-height: 100vh;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}
.leftNav::-webkit-scrollbar {
  width: 8px;
}

.leftNav::-webkit-scrollbar-track {
  background: transparent;
}

.leftNav::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  visibility: hidden;
}

.leftNav:hover::-webkit-scrollbar-thumb,
.leftNav:active::-webkit-scrollbar-thumb {
  visibility: visible;
}/* Style the board list */
.board-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }
  
  /* Style the board list items */
  .board-item {
    box-sizing: border-box;
    border-left: 3px solid transparent;
    display: flex;
    align-items: center;
    border-radius: .28571429rem;
    margin-bottom: 0;
    transition: background-color 0.3s;
    height: 39.5px !important;
  }

  /* Remove space between list items */
  .board-item + .board-item {
    margin-top: 0;
  }

  
  /* Style the board button */
  .board-button {
    background: none;
    border: none;
    padding: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s;
  }
  
  /* Style the star icon */
  .star.icon {
    color: #FFD700; /* Change the color as needed */
    margin-right: 10px;
    margin-top: 5px;
  }
  
  /* Style the board name */
  .board-name {
    color: black;
    font-size: 14px;
    padding-left: 25px;
    font-weight: 500;
  }
  
  /* Hover state for board items */
  .board-item:hover {
    /* background: linear-gradient(to top right, #8eefca, #8dc3e7); */
    background-color: rgb(84, 161, 229, 0.2) ;
    border-radius: .28571429rem;
    text-decoration: #324aeb !important;
    /* border-left: 3px solid #0087ff; */

  }
  
  /* Active (click) state for board button */
  .board-button:active {
    color: #4CAF50; /* Change the color on click */
  }

  .board-button:focus {
    color: purple;
  }
  
  .MuiButton{
    border-radius: 10;
  }

  .selected-board {
    background-color: rgb(84, 161, 229, 0.2); /* Add your desired background color */
    border-radius: .28571429rem;
    color: rgb(85, 160, 228) !important;
    /* border-left: 3px solid #0087ff; */
  }
  
  .focused-board{
    color: red;
  }

  /* .edit-button-hidden {
    display: none; 
  }
  
  .board-item:hover .edit-button-hidden {
    display: block;
  }

  .board-item:active .edit-button-hidden {
    display: block; 
  } */

  /* Hide the edit button by default */
.edit-button-hidden {
  display: none;
  position: relative !important; /* Position relative for the menu positioning */
}

/* Show the edit button when its parent list item is hovered */
.board-item:hover > .edit-button-hidden {
  display: block; /* Visible when focused */

}

/* Optional: Ensure the menu appears right below the edit button */
.MuiMenu-root {
  position: absolute;
  top: 100%; /* Position the menu right below the button */
  left: 30px; /* Align the menu to the left of the button */
  z-index: 1000; /* Ensure the menu is above other content */
}

.board-item {
  transition: opacity 0.7s ease-in-out; /* Smooth transition for better UX */
}

.editBoardElipses {
  opacity: 0; /* Start as invisible */
}

.board-item.focused-board .editBoardElipses {
  opacity: 1; /* Visible when focused */
}


.edit-button-visible {
  opacity: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;
}

.board-item:hover .edit-button-visible {
  opacity: 1;
  visibility: visible;
}

  